import { useState } from 'react';
import api from '../../api/axiosConfig';
import './Register.css';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    taiwaneseName: '',
    englishName: '',
    birthday: '',
    gender: '',
    contact_number: '',
    line_id: '', // Updated field for Line ID
    email: '',
    current_citizenship: '', // Updated field for citizenship

    school: '',
    program: '',
    education_level: '', // Updated field for education level
    year_of_study: '',
    date_of_enrollment: '', // Updated field for enrollment date
    date_of_graduation: '', // Updated field for graduation date
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state

  const blockedEmailDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'aol.com',
    'mail.com',
    'zoho.com',
    'yandex.com',
    'qq.com',
    'live.com'
    // You can add more common public email domains here
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    // Check if any field is empty
    if (Object.values(formData).some(field => field === '')) {
      setError('資料送出失敗！！');
      setLoading(false);
      return; // Prevent form submission
    }

    const emailDomain = formData.email.split('@')[1]; // Extract domain from email

    if (blockedEmailDomains.includes(emailDomain)) {
      alert('不允許個人郵箱。請使用學校郵件信箱！');
      setLoading(false);
      return; // Block form submission
    }
  
    try {
      const response = await api.post('/userApi/register', formData);
  
      if (response.status === 200) {
        // Show success alert
        alert('已成功提交，請於15分鐘內完成電子郵件驗證。');
        navigate('/');  // Redirect to the home page after showing the alert
      }
    } catch (err) {
      // Show error alert
      alert('註冊失敗，請稍後再試。');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-background">
      <div className='pt-5'></div>
      

      <div className='home-heading'>
        <h1 style={{ fontFamily: 'serif', fontSize: '60px', fontWeight: 'bold', color: 'black' }}>
          註冊頁面
        </h1>
      </div>


      <div className='home-heading'>
    

        <div className='pt-5'></div>
        
        <p className="welcome-text">
          <h2>使用需知</h2><br/>
          <strong>（一） 請填入 "個人資料" 和 "學校資訊" 以申請會員</strong><br/><br/>
          <strong>（二） 請使用學校郵件信箱！不接受個人 Email</strong><br/><br/>
          <strong>（三） 提交後 ，請至信箱做 Email 驗證。（請注意， 必須完成 Email 驗證，資料才會成功送出）</strong><br/><br/>
          <strong>（四） 資料送出後，請等待人工審核會員資格。(約1~3個工作天)</strong><br/><br/>
          <strong>（五） 審核通過後，您的會員卡將透過郵件寄出</strong><br/><br/>
        </p>
      </div>


      <div className='pt-5'></div>
      <div className='pt-5'></div>

      <form onSubmit={handleSubmit} className="register-form">
        <h2 className="section-title">個人資訊</h2>
        <div className="form-group">
          <label>中文名:</label>
          <input
            type="text"
            name="taiwaneseName"
            value={formData.taiwaneseName}
            onChange={handleInputChange}
            required
            placeholder="例：王小明"
          />
        </div>
        <div className="form-group">
          <label>英文名（護照上）:</label>
          <input
            type="text"
            name="englishName"
            value={formData.englishName}
            onChange={handleInputChange}
            required
            placeholder="例：Wang Xiao-Ming"
          />
        </div>
        <div className="form-group">
          <label>生日:</label>
          <input
            type="text"
            name="birthday"
            value={formData.birthday}
            onChange={handleInputChange}
            required
            placeholder="例：2001-05-09"
          />
        </div>
        <div className="form-group">
          <label>性別:</label>
          <select 
            name="gender" 
            value={formData.gender} 
            onChange={handleInputChange}
            required
          >
            <option value="">請選擇</option>
            <option value="男性">男性</option>
            <option value="女性">女性</option>
          </select>
        </div>
        <div className="form-group">
          <label>聯絡電話:</label>
          <input
            type="text"
            name="contact_number"
            value={formData.contact_number}
            onChange={handleInputChange}
            required
            placeholder="例：+65 98761234"
          />
        </div>
        <div className="form-group">
          <label>Line ID:</label>
          <input
            type="text"
            name="line_id"
            value={formData.line_id}
            onChange={handleInputChange}
            required
            placeholder="例：mingming123"
          />
        </div>
        
        <div className="form-group">
          <label>學校 Email （不接受個人 Email）:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            placeholder="例：xiaoming123@@e.ntu.edu.sg"
          />
        </div>

        <div className="form-group">
          <label>目前身份:</label>
          <select 
            name="current_citizenship" 
            value={formData.current_citizenship}
            onChange={handleInputChange}
            required
          >
            <option value="">請選擇</option>
            <option value="Taiwanese">持中華民國護照</option>
            <option value="Singaporean">持新加坡護照</option>
            <option value="PR">持中華民國護照＋新加坡 PR</option>
          </select>
        </div>
        
        <h2 className="section-title">學校資訊</h2>
        <div className="form-group">
          <label>學校:</label>
          <select 
            name="school" 
            value={formData.school} 
            onChange={handleInputChange}
            required
          >
            <option value="">請選擇</option>
            <option value="新加坡國立大學 (NUS)">新加坡國立大學 (NUS)</option>
            <option value="南洋理工大學 (NTU)">南洋理工大學 (NTU)</option>
            <option value="新加坡管理大學">新加坡管理大學 (SMU)</option>
            <option value="新加坡管理學院">新加坡管理大學 (SIM)</option>
            <option value="新加坡理工大學 (SIT)">新加坡理工大學 (SIT)</option>
            <option value="新加坡科技設計大學 (SUTD)">新加坡科技設計大學 (SUTD)</option>
            <option value="新加坡社科大學 (SUSS)">新加坡社科大學 (SUSS)</option>
            <option value="詹姆士庫克大學">詹姆士庫克大學</option>
            <option value="PSB Academy">PSB Academy</option>
            <option value="洛桑酒店管理學院">洛桑酒店管理學院</option>
            <option value="歐洲工商管理學院">歐洲工商管理學院</option>
            <option value="SP Jain全球管理學院">SP Jain全球管理學院</option>
            <option value="歐洲工商管理學院">歐洲工商管理學院</option>
            <option value="迪吉彭理工學院">迪吉彭理工學院</option>
            <option value="科廷大學新加坡校區">科廷大學新加坡校區</option>
            <option value="慕尼黑工業大學亞洲校區">慕尼黑工業大學亞洲校區</option>
            <option value="巴黎第二大學">巴黎第二大學</option>
            <option value="St.Gallen Institute of Management in Asia">St.Gallen Institute of Management in Asia</option>
          </select>
        </div>
        <div className="form-group">
          <label>科系:</label>
          <input
            type="text"
            name="program"
            value={formData.program}
            onChange={handleInputChange}
            required
            placeholder="例：Computer Science"
          />
        </div>
        <div className="form-group">
          <label>學位:</label>
          <select 
            name="education_level"
            value={formData.education_level}
            onChange={handleInputChange}
            required
          >
            <option value="">請選擇</option>
            <option value="學士">學士</option>
            <option value="碩士">碩士</option>
            <option value="博士">博士</option>
          </select>
        </div>

        <div className="form-group">
          <label>年級:</label>
          <select 
            name="year_of_study"
            value={formData.year_of_study}
            onChange={handleInputChange}
            required
          >
            <option value="">請選擇</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
        </div>

        <div className="form-group">
          <label>入學年月:</label>
          <input
            type="text"
            name="date_of_enrollment"
            value={formData.date_of_enrollment}
            onChange={handleInputChange}
            required
            placeholder="例：2022-08"
          />
        </div>

        <div className="form-group">
          <label>預計畢業年月:</label>
          <input
            type="text"
            name="date_of_graduation"
            value={formData.date_of_graduation}
            onChange={handleInputChange}
            required
            placeholder="例：2026-08"
          />
        </div>

        {error && <div className="error-message">{error}</div>}
        {loading && <div className="spinner"></div>}  {/* Spinner */}

        <div className="submit-button pt-3">
          <button type="submit" disabled={loading}>提交</button>
        </div>
      </form>

      <div className='pt-5'></div>

      <div className="back-home">
        <button type="button" onClick={() => navigate('/')}>返回首頁</button>
      </div>

      <div className='pt-5'></div>
      <div className='pt-5'></div>
    </div>
  );
};

export default Register;
