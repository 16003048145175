import React, { useState } from 'react';
import QrScanner from 'react-qr-scanner';
import api from '../../api/axiosConfig';
import './QRCodeScanner.css'; // Import the CSS file

const QRCodeScanner = () => {
  const [scanResult, setScanResult] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleScan = (data) => {
    if (data) {
      setScanResult(data.text);
      const uuid = extractUUIDFromURL(data.text);
      fetchUserAndUpdateActivity(uuid);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError('Scanning failed. Please try again.');
  };

  const extractUUIDFromURL = (url) => {
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  const fetchUserAndUpdateActivity = async (uuid) => {
    try {
      // Fetch user details first
      const userResponse = await api.get(`/userApi/member/${uuid}`);
      if (userResponse.status === 200) {
        const userName = userResponse.data.taiwaneseName;

        // Now update the user's activity
        const activityResponse = await api.put(`/userApi/activities/${uuid}`, {
          activity: '2024BBQ',
        });

        if (activityResponse.status === 200) {
          setSuccessMessage(`${userName} 報到成功`);
        } else {
          setError('Failed to add activity');
        }
      } else {
        setError('User not found');
      }
    } catch (err) {
      console.error(err);
      setError('An error occurred while processing the user.');
    }
  };

  return (
    <div className="container">
      <h2 className="header">2024 STSA 中秋烤肉線上報到</h2>
      <div className="scanner-container">
        <QrScanner
          delay={300}
          onError={handleError}
          onScan={handleScan}
          className="qr-scanner"
        />
      </div>
      {scanResult && <p className="result-text">Scanned URL: {scanResult}</p>}
      {successMessage && <p className="success-text">{successMessage}</p>}
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export default QRCodeScanner;
