import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AllMembers from '../MyAllMembers/AllMembers';
import './AllMembersProtected.css';
import api from '../../api/axiosConfig';

const AllMembersProtected = () => {
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await api.post(`userApi/adminpage/password`, password);
    
            if (response.data === true) {
                setIsAuthenticated(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert('An error occurred. Please try again.');
            }
        }
    };
    

    if (isAuthenticated) {
        return <AllMembers />;
    }

    return (
        
        <div className="password-protect-page">
            <h2>進入系統後台</h2>
            <form onSubmit={handlePasswordSubmit}>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="輸入密碼"
                />
                <button type="submit">送出</button>
            </form>
        </div>
    );
};

export default AllMembersProtected;
